import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const useLocationStore = defineStore({
    id: 'locationStore',
    state: () => ({
        locations: useLocalStorage('locations', []),
        mappedLocations: useLocalStorage('mappedLocations', {})
    }),
    getters: {
        mapped: (state) => {
            var locs = {};
            state.locations.forEach((l) => {
                locs[l.id] = l;
            });
            return locs;
        }
    }
});
