import { createRouter, createWebHistory } from 'vue-router';
import AppLayout from '@/layout/AppLayout.vue';
import { userSessionStore } from '@/stores/userSession';

const router = createRouter({
    // history: createWebHashHistory(),
    history: createWebHistory(),

    routes: [
        {
            path: '/',
            component: AppLayout,
            children: [
                {
                    path: '/',
                    name: 'dashboard',
                    component: () => import('@/views/Dashboard.vue')
                },

                {
                    path: '/EventLocationForm',
                    name: 'newLocationForm',
                    component: () => import('@/views/pages/LocationEditor.vue')
                },
                // {
                //     path: '/Locations/:id/edit',
                //     name: 'locationEdit',
                //     component: () => import('@/views/pages/LocationEditor.vue')
                // },
                {
                    path: '/Locations/:id',
                    name: 'locationInfo',
                    component: () => import('@/views/pages/LocationInfo.vue'),
                    children: [
                        {
                            path: '',
                            name: 'location-info',
                            component: () => import('@/components/location/info.vue')
                        },
                        {
                            path: 'events',
                            name: 'location-events',
                            component: () => import('@/components/location/locationPublicCalendar.vue')
                        },
                        {
                            path: 'edit',
                            name: 'location-edit',
                            component: () => import('@/components/location/edit.vue')
                        },
                        {
                            path: 'import',
                            name: 'location-import',
                            component: () => import('@/components/location/import.vue')
                        }
                    ]
                },

                {
                    path: '/Locations/:id/events',
                    name: 'locationEvents',
                    component: () => import('@/views/pages/LocationInfo.vue')
                },
                {
                    path: '/Locations',
                    name: 'locationsList',
                    component: () => import('@/views/pages/LocationList.vue')
                },

                {
                    path: '/planner',
                    name: 'planner',
                    component: () => import('@/views/pages/UserPlanner.vue')
                },
                {
                    path: '/planner/myEvents',
                    name: 'plannerEvents',
                    component: () => import('@/views/pages/ListMyEditableEvents.vue')
                },
                {
                    path: '/publish',
                    name: 'publish',
                    component: () => import('@/views/pages/EventPublisher.vue')
                },
                {
                    path: '/timeMachine',
                    name: 'timeMachine',
                    component: () => import('@/views/pages/Events2023.vue')
                },
                {
                    path: '/resetPassword',
                    name: 'resetPassword',
                    component: () => import('@/views/pages/auth/resetPassword.vue')
                },
                {
                    path: '/PlanningForIndividuals',
                    name: 'PlanningForIndividuals',
                    component: () => import('@/views/pages/info/PlanningForIndividuals.vue')
                },
                {
                    path: '/PlanningForGroups',
                    name: 'PlanningForGroups',
                    component: () => import('@/views/pages/info/PlanningForGroups.vue')
                },
                {
                    path: '/Directory',
                    name: 'Directory',
                    component: () => import('@/views/pages/Directory.vue')
                },
                {
                    path: '/Directory/:id',
                    name: 'EventViewDirectory',
                    component: () => import('@/views/pages/Directory.vue')
                },
                {
                    path: '/account',
                    name: 'Account',
                    component: () => import('@/views/pages/UserAccount.vue')
                },
                {
                    path: '/admin/events',
                    name: 'eventAdmin',
                    component: () => import('@/views/pages/ModerateEvents.vue')
                },
                {
                    path: '/admin/events/export',
                    name: 'eventExport',
                    component: () => import('@/views/pages/ExportEvents.vue')
                },
                {
                    path: '/admin/locations',
                    name: 'locationAdmin',
                    component: () => import('@/views/pages/ModerateLocations.vue')
                }
            ]
        },
        {
            path: '/help',
            name: 'help',
            component: () => import('@/views/pages/help/HelpSite.vue')
        }
        // {
        //     path: '/landing',
        //     name: 'landing',
        //     component: () => import('@/views/pages/Landing.vue')
        // },
        // {
        //     path: '/pages/notfound',
        //     name: 'notfound',
        //     component: () => import('@/views/pages/NotFound.vue')
        // },

        // {
        //     path: '/auth/login',
        //     name: 'login_old',
        //     component: () => import('@/views/pages/auth/Login.vue')
        // },
        // {
        //     path: '/auth/access',
        //     name: 'accessDenied',
        //     component: () => import('@/views/pages/auth/Access.vue')
        // },
        // {
        //     path: '/auth/error',
        //     name: 'error',
        //     component: () => import('@/views/pages/auth/Error.vue')
        // }
    ]
});

router.beforeEach((to, from, next) => {
    const userSession = userSessionStore();

    if (to.meta.needsAuth) {
        if (userSession.session) {
            return next();
        } else {
            return next('/');
        }
    }

    return next();
});

export default router;
