import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';

export const userSessionStore = defineStore({
    id: 'userSession',
    state: () => ({
        session: null,
        savedEvents: useLocalStorage('savedEvents', {}),
        preferences: useLocalStorage('userPreferences', { moderateFilter: {}, moderateColumns: {} })
    }),
    getters: {
        favourites: (state) => {
            var saved = [];
            Object.keys(state.savedEvents).forEach((l) => {
                    
                if (state.savedEvents[l].active) {
                    saved.push(state.savedEvents[l].event_id);
                }
            });
            return saved;
        }
    }
});
