<script setup>
import { ref, computed } from 'vue';
import { userSessionStore } from '@/stores/userSession';
const userSession = userSessionStore();

const loggedIn = computed(() => {
    return userSession.session ? true : false;
});

// const eventsAdmin = computed(() => {
//     return userSession?.session?.user?.app_metadata?.events_admin === true ? true : false;
// });

const barrioAdmin = computed(() => userSession?.session?.user?.app_metadata?.events_admin === true);

function hasGroup(group) {
    return userSession?.session?.user?.app_metadata[group] === true ? true : false;
}

import AppMenuItem from './AppMenuItem.vue';
const model = ref([
    {
        label: 'Home',
        items: [
            {
                label: 'Dashboard',
                to: '/',
                icon: 'mdi mdi-home-outline mdi-24px text-primary',
                tooltip: 'Home screen'
            }
        ]
    },
    // {
    //     label: 'Locations/Barrios Admin',
    //     requiresAuth: true,
    //     requiresGroup: 'barrio_admin',
    //     items: [
    //         {
    //             label: 'Moderate Locations',
    //             to: '/admin/locations',
    //             icon: 'mdi mdi-shield-crown mdi-24px text-primary',
    //             tooltip: 'Manage Barrios, n0rg venues, art, etc...'
    //         }
    //     ]
    // },
    {
        label: 'Events Admin',
        requiresAuth: true,
        requiresGroup: 'events_admin',
        items: [
            {
                label: 'Moderate Events',
                to: '/admin/events',
                icon: 'mdi mdi-shield-crown mdi-24px text-primary',
                tooltip: 'Review and approve or reject submitted events'
            },
            {
                label: 'Export Events',
                to: '/admin/events/export',
                icon: 'mdi mdi-file-word mdi-24px text-primary',
                tooltip: 'Export events as a Word Document, ready for placing into InDesign for the published Guide'
            }
        ]
    },
    {
        label: 'My stuff',
        requiresAuth: true,

        items: [
            {
                label: 'My Event Planner',
                to: '/planner',
                requiresAuth: true,
                icon: 'mdi mdi-calendar-account mdi-24px text-primary',
                tooltip: 'Plan your own time at Nowhere. Schedule your events here, before using the "My List / Admin" link to then add them to the Public Directory'
            },
            {
                label: 'My Account',
                to: '/account',
                requiresAuth: true,
                icon: 'mdi mdi-account mdi-24px text-primary',
                tooltip: 'Manage your account, change your password.'
            }
        ]
    },
    {
        label: 'Directory',
        items: [
            {
                label: 'Event Directory',
                to: '/Directory',
                icon: 'mdi mdi-book-open-variant mdi-24px text-primary',
                tooltip: 'Browse the full list of events that are running, and save them to your own planner.'
            },

            { label: 'Locations', to: '/Locations', icon: 'mdi mdi-map-search-outline mdi-24px text-primary', tooltip: 'See the list of Barrios, Art Installations, Nowhere venues and more.' },
            {
                label: 'Add a Location',
                to: '/EventLocationForm',
                icon: 'mdi mdi-map-marker-plus-outline mdi-24px text-primary',
                tooltip: 'Is your Barrio not listed? Want to add your Art project?'
            }
        ]
    },
    {
        label: "Previous year's events...",
        items: [
            {
                label: '2023: view online',
                to: '/timeMachine',
                icon: 'mdi mdi-archive-eye mdi-24px text-primary',
                tooltip: 'Not quite a hot tub timemachine, but the list of events from 2023...'
            },
            {
                label: 'Older events... coming soon'
            }
        ]
    },
    {
        label: 'Help / Info',
        items: [
            {
                label: 'Planning YOUR time',
                to: '/PlanningForIndividuals',
                icon: 'mdi mdi-account-heart mdi-24px text-primary',
                tooltip: 'Find out more about what you can do here to have your best ever Nowhere'
            },

            {
                label: 'Event Planning for Barrios, Norg venues, Art...',
                to: '/PlanningForGroups',
                icon: 'mdi mdi-home-group mdi-24px text-primary',
                tooltip: 'The What Where When Guide can help you collaborate on planning your time at Nowhere. Plan together with others and make it easier for everyone.'
            }
        ]
    }
]);
</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in model" :key="item">
            <app-menu-item v-if="!item.separator && (!item.requiresAuth || (item.requiresAuth && loggedIn === true && !item.requiresGroup) || (item.requiresGroup && hasGroup(item.requiresGroup)))" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>
</template>

<style lang="scss" scoped></style>
