<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useLayout } from '@/layout/composables/layout';
import { useRouter } from 'vue-router';
import OverlayPanel from 'primevue/overlaypanel';
const { layoutConfig, onMenuToggle } = useLayout();

const outsideClickListener = ref(null);
const topbarMenuActive = ref(false);
// pinia
import { userSessionStore } from '@/stores/userSession.js';
import { useCalendarEventStore } from '@/stores/calendarEventStore.js';
const userSession = userSessionStore();
const calendarEventStore = useCalendarEventStore();
import { supabase } from '@/composables/SupaBase.js';
// themer
// const { onMenuToggle } = useLayout();
const emit = defineEmits(['loginDialog']);

function loginDialog() {
    emit('loginDialog');
}

const router = useRouter();
onMounted(() => {
    bindOutsideClickListener();
});

onBeforeUnmount(() => {
    unbindOutsideClickListener();
});
const logoUrl = computed(() => {
    // return `layout/images/${layoutConfig.darkTheme.value ? 'logo-white' : 'logo-dark'}.svg`;
    return '/nowhere-compass.png';
});
const onTopBarMenuButton = () => {
    topbarMenuActive.value = !topbarMenuActive.value;
};
const toggle = (event) => {
    showBetaMenu.value.toggle(event);
};
const showBetaMenu = ref();
const topbarMenuClasses = computed(() => {
    return {
        'layout-topbar-menu-mobile-active': topbarMenuActive.value
    };
});

const onAccountClick = () => {
    topbarMenuActive.value = false;
    router.push('/account');
};

const logout = async () => {
    topbarMenuActive.value = false;
    try {
        const { error } = await supabase.auth.signOut();
        if (error) {
            throw error;
        } else {
            userSession.savedEvents = {};
            calendarEventStore.events = {};
            router.push('/');
        }
    } catch (error) {
        alert(error.message);
    }
};
const bindOutsideClickListener = () => {
    if (!outsideClickListener.value) {
        outsideClickListener.value = (event) => {
            if (isOutsideClicked(event)) {
                topbarMenuActive.value = false;
            }
        };
        document.addEventListener('click', outsideClickListener.value);
    }
};
const unbindOutsideClickListener = () => {
    if (outsideClickListener.value) {
        document.removeEventListener('click', outsideClickListener);
        outsideClickListener.value = null;
    }
};
const isOutsideClicked = (event) => {
    if (!topbarMenuActive.value) return;

    const sidebarEl = document.querySelector('.layout-topbar-menu');
    const topbarEl = document.querySelector('.layout-topbar-menu-button');

    return !(sidebarEl.isSameNode(event.target) || sidebarEl.contains(event.target) || topbarEl.isSameNode(event.target) || topbarEl.contains(event.target));
};
</script>

<template>
    <div class="layout-topbar">
        <button class="p-link layout-topbar-button layout-menu-toggle" @click="onMenuToggle()">
            <i class="pi pi-bars"></i>
        </button>
        <router-link to="/" class="layout-topbar-logo">
            <!-- <img :src="logoUrl" alt="logo" /> -->
            <h1 class="text-primary  lg:text-5xl">What Where When</h1>
        </router-link>
        <button class="p-link layout-topbar-menu-button layout-topbar-button" @click="onTopBarMenuButton()"><i class="mdi mdi-account mdi-24px"></i></button>
        <div class="layout-topbar-menu" :class="topbarMenuClasses">
            <Button label="Found a bug?" class="py-0 px-2 m-0 mt-2 mr-2" style="height: 28px" icon="mdi mdi-bug text-2xl" size="small" severity="danger" rounded @click="toggle" />
            <div v-if="!userSession?.session?.access_token"><Button @click="loginDialog" class="text-lg">Login / Create Account</Button></div>
            <div v-else>
                <button @click="onAccountClick()" class="p-link layout-topbar-button" v-tooltip.bottom="'Logged in as ' + userSession.session.user.email + ': Manage your account details, change your password.'">
                    <i class="pi pi-user"></i>
                    <span>Profile / Account</span>
                </button>
                <button @click="logout()" class="p-link layout-topbar-button" v-tooltip.bottom="'Logout'">
                    <i class="mdi mdi-logout"></i>
                    <span>Logout</span>
                </button>
            </div>
        </div>
    </div>
    <OverlayPanel ref="showBetaMenu">
        <div class="gap-3">
            <div class="mt-3">
                <p class="text-lg">Please send any feedback on bugs you encounter.</p>
                <p>Describe</p>
                <ul>
                    <li>what you were doing</li>
                    <li>what you expected to happen</li>
                    <li>what actually happened</li>
                </ul>
                <p>If you can get any screenshots of things going wrong, that would help too!</p>
                <a class="p-component p-button p-button-danger text-lg" href="mailto:scally@goingnowhere.org?subject=wwwg%20feedback">Send beta feedback!</a>
            </div>
        </div>
    </OverlayPanel>
</template>

<style lang="scss" scoped>
.nabla-wwwg {
    font-family: 'Nabla', system-ui;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    font-variation-settings: 'EDPT' 200, 'EHLT' 24;
}
</style>
